import React, { Suspense, StrictMode, useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, topbar } from "react-router-loading";
import { CartProvider } from "./contexts/CartContext";
import { WishlistProvider } from "./contexts/WishlistContext";
import { CurrencyProvider } from "./contexts/CurrencyContext";
import Footer from "./components/Footer";
import { AccountContext, AccountProvider } from "./contexts/AccountContext";
import { FiltersProvider } from "./contexts/FiltersContext";
import Success from "./pages/Success";
import TestOuss from "./pages/testOuss";
import Failed from "./pages/Failed";
import Test123 from "./components/phoneHanlder";
import Information from "./pages/Information";
import Anythingv2 from "./pages/Anythingv2";
import Version from "./components/Version";
import CheckoutV3 from "./pages/CheckoutV3";
import { useSellerContext } from "./contexts/SellerContext";
import AsideMenu from "./components/AsideMenu";
import AccountingSeller from "./pages/seller_report/Accounting";
import LoaderOverlay from "./components/LoaderOverlay";
import { useWindowDimensions } from "./components/TopHeader";
import { ProductProvider } from "./contexts/ProductContext";
import Product from "./pages/Product";
import Productv2 from "./pages/Productv2";
import PrintOrderInvoice from "./pages/seller_report/PrintOrderInvoice";
import Contact from "./pages/Contact";
import { useLocation } from "react-router-dom";
import PosOrders from "./pages/PosOrders";
import Pos from "./pages/Pos";
import PosPrinttest from "./components/Posprint";
import PosPrintHold from "./pages/PosPrintHold";
import { Hc } from "./pages/Hc";
import PosOrdersPrint from "./pages/PosOrdersPrint";
import { FiAlertCircle } from "react-icons/fi";
import { ExportProductProvider } from "./contexts/ExportProductContext";
import Home from "./pages/Home";
import Slug from "./pages/Slug";
import Coupons from "./pages/Coupons";
import CheckIn from "./pages/CheckIn";
import CartShared from "./pages/SharedCart";
import MenmberShip from "./pages/MemberShip";
import MenmberShipDetails from "./pages/MemberShipDetails";
import { MessageProvider } from "./contexts/MessageContext";
import Wallet from "./pages/wallet";
import Follow from "./pages/follow";
import ReviewCenter from "./pages/reviewCenter";
import { ReviewCenterProvider } from "./contexts/ReviewCenterContext";
import Feedback from "./pages/feedback";
import WishlistSimilar from "./pages/wishlistSimilar";
import { MarketingProvider } from "./contexts/MarketingContext";
import { HcItem } from "./pages/HcItem";
import BottomBar from "./components/bottomBar";
import CategoryList from "./pages/AllCategories";
import { GoogleOAuthProvider } from '@react-oauth/google';
import TopAccount from "./components/TopAccount";
import Account from "./components/Account";

// import SuggestionDetails from "./pages/[...suggestion-id]";

const Latest = React.lazy(() => import("./pages/Latest"));
// const Home = React.lazy(() => import("./pages/Home"));
const Cart = React.lazy(() => import("./pages/Cart"));
const Catalog = React.lazy(() => import("./pages/Catalog"));
const Addresses = React.lazy(() => import("./pages/Addresses"));
const AddAddresses = React.lazy(() => import("./pages/AddAddress"));
const WishList = React.lazy(() => import("./pages/WishListest"));
/////
const AddressesT = React.lazy(() => import("./pages/Addresses-test"));
const AddAddressesT = React.lazy(() => import("./pages/AddAddress-test"));
// const AddressesTV2 = React.lazy(() => import("./pages/Addresses-test"));
// const AddAddressesTV2 = React.lazy(() => import("./pages/AddAddressv2"));
const WishListT = React.lazy(() => import("./pages/WishList"));
const ProfileT = React.lazy(() => import("./pages/Profile-test"));
const OrdersT = React.lazy(() => import("./pages/Orders-test"));
////
// const Product = React.lazy(() => import("./pages/Product"));
// const Productv2 = React.lazy(() => import("./pages/Productv2"));
const TopHeader = React.lazy(() => import("./components/TopHeader"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Orders = React.lazy(() => import("./pages/Orders"));
const OrderDetails = React.lazy(() => import("./pages/OrderDetails"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const Search = React.lazy(() => import("./pages/Search"));
const ImageUpload = React.lazy(() => import("./components/ImageUpload"));
const ProductPreview = React.lazy(() => import("./pages/ProductPreview"));
const BuyAgain = React.lazy(() => import("./pages/BuyAgain"));
const RecentlyViewed = React.lazy(() => import("./pages/RecentlyViewed"));
const posPrint = React.lazy(() => import("./components/Posprint"));

const WidgetsHomePreview = React.lazy(() =>
  import("./pages/WidgetsHomePreview")
);
const Anything = React.lazy(() => import("./pages/Anything"));
const Upc = React.lazy(() => import("./components/Upc"));
const UpcDetails = React.lazy(() => import("./components/UpcDetails"));
const Careers = React.lazy(() => import("./pages/Careers"));
const Career = React.lazy(() => import("./pages/Career"));
const UpcMobile = React.lazy(() => import("./pages/UpcMobile"));
const Testheader = React.lazy(() => import("./pages/HeaderV2test"));
const Notifications = React.lazy(() => import("./pages/Notification"));

const QuickBuyAdmintest = React.lazy(() =>
  import("./pages/QuickBuyAdmin-test")
);
const MpgsSuccess = React.lazy(() => import("./pages/MpgsSuccess"));
const UbaMpgsSuccess = React.lazy(() => import("./pages/UbaMpgsSuccess"));
const PaymentSuccess = React.lazy(() => import("./pages/PaymentSuccess"));
const OrderReviews = React.lazy(() => import("./pages/OrderReviews"));
const DashboardSeller = React.lazy(() => import("./pages/seller_report/Home"));
const ReturnOrdersSeller = React.lazy(() =>
  import("./pages/seller_report/ReturnOrders")
);
const ReturnOrderDetails = React.lazy(() =>
  import("./pages/seller_report/ReturnOrderDetails")
);

const ProductsSeller = React.lazy(() =>
  import("./pages/seller_report/Products")
);
// const SellerEditProduct = React.lazy(() => import("./pages/seller_report/editProduct"));
const OrdersSeller = React.lazy(() => import("./pages/seller_report/Orders"));
const ReportsSeller = React.lazy(() => import("./pages/seller_report/Reports"));
const OrderSellerDetails = React.lazy(() =>
  import("./pages/seller_report/OrderDetails")
);
const SoldProductDetails = React.lazy(() =>
  import("./pages/seller_report/SoldProductDetails")
);
const SellerEdit = React.lazy(() => import("./pages/seller_report/EditSeller"));
const ProductEdit = React.lazy(() =>
  import("./pages/seller_report/EditProduct")
);

const NotFound = React.lazy(() => import("./pages/NotFound"));
const MyLoadingScreen = () => <LoaderOverlay />;

function App() {
  const { isUserSeller, setIsUserSeller } = useSellerContext();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const currentPage = location.pathname;

  topbar.config({
    autoRun: true,
    barThickness: 1,
    barColors: {
      0: "rgba(0, 0, 0, 0)",
    },
    shadowColor: "transparent",
    // shadowBlur: 1,
    className: width > 650 ? "loader" : "loader-mobile",
  });
  // alert(window.location.href.split('/')[3] )
  return (
    <div
      className={` ${
        currentPage === "/pos" ||
        window.location.href.split("/")[3] === "posprint"
          ? "max-h-screen bottom-0 fixed"
          : " overflow-x-hidden bg-dgreyProduct bg-opacity-10 min-h-screen"
      }`}
      style={{ maxWidth: "100vw !important" }}
    >
      <StrictMode>
        {window.location.href.split("/")[3] === "bey" ? (
          window.location.href.split("/")[4] === "posprint" ||
          window.location.href.split("/")[4] === "ordersprint" ||
          window.location.href.split("/")[4] === "posprinthold" ? (
            <>
              <Routes>
                <Route path="/bey/posprint/:id">
                  <PosPrinttest />
                </Route>
                <Route path="/bey/ordersprint/">
                  <PosOrdersPrint />
                </Route>
                <Route path="/bey/posprinthold/:id">
                  <PosPrintHold />
                </Route>
              </Routes>
            </>
          ) : (

            <AccountProvider>
            <MessageProvider>
              <FiltersProvider>
                <CartProvider>
                  <WishlistProvider>
                    <CurrencyProvider>
                      <AccountProvider>
                        <ProductProvider>
                          <ExportProductProvider>
                            {!isUserSeller ? <TopHeader /> : <AsideMenu />}
                            <div
                              className="text-dblack min-h-screen"
                              // style={{ backgroundColor: "#f7f7fa" }}
                            >
                              <Routes>
                                <Route path="/bey/" element={<Home />} />

                                <Route
                                  path="/bey/upload/upload"
                                  element={<ImageUpload />}
                                />

                                <Route
                                  path="/bey/print/print"
                                  element={<Upc />}
                                />

                                <Route
                                  path="/bey/print/details"
                                  element={<UpcDetails />}
                                />

                                <Route
                                  path="/bey/careers/all"
                                  element={<Careers />}
                                />

                                <Route
                                  path="/bey/flo-upc"
                                  element={<UpcMobile />}
                                />

                                <Route path="/bey/pos" element={<Pos />} />

                                <Route
                                  path="/bey/fastbuy-admin-test"
                                  exact
                                  element={<QuickBuyAdmintest />}
                                />

                                <Route
                                  path="/bey/test-payment/v3"
                                  exact
                                  element={<CheckoutV3 />}
                                />

                                <Route
                                  path="/bey/career/:id"
                                  element={<Career />}
                                />

                                <Route
                                  path="/bey/search"
                                  exact
                                  element={<Search />}
                                />

                                <Route
                                  path="/bey/latest"
                                  loading
                                  element={<Latest />}
                                />

                                <Route path="/bey/cart" element={<Cart />} />

                                <Route
                                  path="/bey/account/addresses"
                                  element={<Addresses />}
                                />

                                <Route
                                  path="/bey/account/buyagain"
                                  element={<BuyAgain />}
                                />

                                <Route
                                  path="/bey/account/profile"
                                  element={<Profile />}
                                />

                                <Route
                                  path="/bey/account/notifications"
                                  element={<Notifications />}
                                />

                                <Route
                                  path="/bey/account/orders"
                                  element={<Orders />}
                                />

                                <Route
                                  path="/bey/account/orders-test"
                                  element={<OrdersT />}
                                />

                                <Route
                                  path="/bey/posprint"
                                  element={<PosPrinttest />}
                                />

                                <Route
                                  path="/bey/account/profile-test"
                                  element={<ProfileT />}
                                />

                                <Route
                                  path={"/bey/account/address/:id/edit-test"}
                                  element={<AddAddressesT />}
                                />

                                <Route
                                  path={"/bey/account/address/add-test"}
                                  element={<AddAddressesT />}
                                />

                                <Route
                                  path="/bey/account/wishlist/"
                                  element={<WishListT />}
                                />

                                <Route
                                  path="/bey/account/order-details"
                                  element={<OrderDetails />}
                                />

                                <Route
                                  path="/bey/account/recentlyViewed"
                                  element={<RecentlyViewed />}
                                />

                                <Route
                                  path="/bey/account/order-reviews"
                                  element={<OrderReviews />}
                                />

                                <Route
                                  path="/bey/failed"
                                  element={<Failed />}
                                />

                                <Route
                                  path="/bey/success"
                                  element={<Success />}
                                />

                                <Route
                                  path="/bey/PaymentSuccess"
                                  element={<PaymentSuccess />}
                                />

                                <Route
                                  path="/bey/account/wishlist-test"
                                  element={<WishList />}
                                />

                                <Route
                                  path="/bey/headertest/v2"
                                  element={<Testheader />}
                                />

                                <Route
                                  path="/bey/checkout"
                                  element={<Checkout />}
                                />

                                <Route
                                  path="/bey/PaySuccess"
                                  element={<MpgsSuccess />}
                                />

                                <Route
                                  path="/bey/UbaPaySuccess"
                                  element={<UbaMpgsSuccess />}
                                />

                                <Route
                                  path={"/bey/account/address/:id/edit"}
                                  element={<AddAddresses />}
                                />

                                <Route
                                  path={"/bey/account/address/add"}
                                  element={<AddAddresses />}
                                />

                                <Route
                                  exact
                                  path={"/bey/category/:id"}
                                  element={<Catalog />}
                                />

                                <Route
                                  exact
                                  path={"/beymanufacturer/:id"}
                                  element={<Catalog />}
                                />

                                <Route
                                  exact
                                  path={"/bey/seller/:id"}
                                  element={<Catalog />}
                                />

                                <Route
                                  exact
                                  path="/bey/:name/c=:id"
                                  element={<Anythingv2 />}
                                />

                                <Route
                                  exact
                                  path="/bey/:name/c=:id"
                                  element={<Anythingv2 />}
                                />

                                <Route
                                  exact
                                  path="/bey/:name/c=:id"
                                  element={<Anythingv2 />}
                                />

                                <Route
                                  path="/bey/product/:id"
                                  element={<Product />}
                                />

                                {/* <Route path="/bey/testouss/:id">
                                  <Suspense fallback={<div></div>}>
                                    <TestOuss />
                                  </Suspense>
                           
                                </Route> */}

                                <Route
                                  path="/bey/:name/p/:id"
                                  exact
                                  element={<Productv2 />}
                                />

                                {/* <Route path="/bey/test123" component={Test123} /> */}
                                <Route
                                  path="/bey/productpreview/:id"
                                  element={<ProductPreview />}
                                />

                                <Route
                                  path="/bey/widgetspreview"
                                  element={<WidgetsHomePreview />}
                                />

                                <Route
                                  path="/bey/seller_report/home"
                                  exact
                                  element={<DashboardSeller />}
                                />

                                <Route
                                  path="/bey/seller_report/products"
                                  exact
                                  element={<ProductsSeller />}
                                />

                                <Route
                                  path="/bey/seller_report/orders"
                                  exact
                                  element={<OrdersSeller />}
                                />

                                <Route
                                  path="/bey/seller_report/printOrderInvoice"
                                  exact
                                  element={<PrintOrderInvoice />}
                                />

                                <Route
                                  path="/bey/seller_report/ReturnOrders"
                                  exact
                                  element={<ReturnOrdersSeller />}
                                />

                                <Route
                                  path="/bey/seller_report/ReturnOrderDetails/:id"
                                  exact
                                  element={<ReturnOrderDetails />}
                                />

                                <Route
                                  path="/bey/seller_report/OrderDetails/:id"
                                  element={<OrderSellerDetails />}
                                />

                                <Route
                                  path="/bey/seller_report/accounting"
                                  exact
                                  element={<AccountingSeller />}
                                />

                                <Route
                                  path="/bey/seller_report/reports"
                                  exact
                                  element={<ReportsSeller />}
                                />

                                <Route
                                  path="/bey/seller_report/edit"
                                  exact
                                  element={<SellerEdit />}
                                />

                                <Route
                                  path="/bey/seller_report/EditProduct/:id"
                                  exact
                                  element={<ProductEdit />}
                                />

                                <Route
                                  path="/bey/orders/"
                                  element={<PosOrders />}
                                />

                                <Route
                                  path="/bey/seller_report/SoldProductDetails/:id"
                                  element={<SoldProductDetails />}
                                />

                                <Route
                                  path="/bey/information/:id"
                                  element={<Information />}
                                />

                                <Route
                                  path="/bey/information/contact"
                                  element={<Contact />}
                                />

                                <Route
                                  path="/bey/contact"
                                  element={<Contact />}
                                />

                                <Route
                                  path="/bey/:name"
                                  exact
                                  element={<Anything />}
                                />

                                <Route
                                  path="/bey"
                                  exact
                                  element={<NotFound />}
                                />
                              </Routes>
                            </div>

                            {!isUserSeller && <Footer />}
                            {!isUserSeller && <Version />}
                          </ExportProductProvider>
                        </ProductProvider>
                      </AccountProvider>
                    </CurrencyProvider>
                  </WishlistProvider>
                </CartProvider>
              </FiltersProvider>
            </MessageProvider>
            </AccountProvider>

          )
        ) : window.location.href.split("/")[3] === "zahle-store" ? (
          window.location.href.split("/")[4] === "posprint" ||
          window.location.href.split("/")[4] === "ordersprint" ||
          window.location.href.split("/")[4] === "posprinthold" ? (
            <>
              <Routes>
                <Route path="/zahle-store/posprint/:id">
                  <PosPrinttest />
                </Route>
                <Route path="/zahle-store/ordersprint/">
                  <PosOrdersPrint />
                </Route>
                <Route path="/zahle-store/posprinthold/:id">
                  <PosPrintHold />
                </Route>
              </Routes>
            </>
          ) : (
            <MessageProvider>
              <FiltersProvider>
                <CartProvider>
                  <WishlistProvider>
                    <CurrencyProvider>
                      <AccountProvider>
                        <ProductProvider>
                          <ExportProductProvider>
                            {!isUserSeller ? <TopHeader /> : <AsideMenu />}
                            <div
                              className="text-dblack min-h-screen"
                              // style={{ backgroundColor: "#f7f7fa" }}
                            >
                              <Routes>
                                <Route
                                  path="/zahle-store/"
                                  element={<Home />}
                                />

                                <Route
                                  path="/zahle-store/upload/upload"
                                  element={<ImageUpload />}
                                />

                                <Route
                                  path="/zahle-store/print/print"
                                  element={<Upc />}
                                />

                                <Route
                                  path="/zahle-store/print/details"
                                  element={<UpcDetails />}
                                />

                                <Route
                                  path="/zahle-store/careers/all"
                                  element={<Careers />}
                                />

                                <Route
                                  path="/zahle-store/flo-upc"
                                  element={<UpcMobile />}
                                />

                                <Route
                                  path="/zahle-store/pos"
                                  element={<Pos />}
                                />

                                <Route
                                  path="/zahle-store/fastbuy-admin-test"
                                  exact
                                  element={<QuickBuyAdmintest />}
                                />

                                <Route
                                  path="/zahle-store/test-payment/v3"
                                  exact
                                  element={<CheckoutV3 />}
                                />

                                <Route
                                  path="/zahle-store/career/:id"
                                  element={<Career />}
                                />

                                <Route
                                  path="/zahle-store/search"
                                  exact
                                  element={<Search />}
                                />

                                <Route
                                  path="/zahle-store/latest"
                                  loading
                                  element={<Latest />}
                                />

                                <Route
                                  path="/zahle-store/cart"
                                  element={<Cart />}
                                />

                                <Route
                                  path="/zahle-store/account/addresses"
                                  element={<Addresses />}
                                />

                                <Route
                                  path="/zahle-store/account/buyagain"
                                  element={<BuyAgain />}
                                />

                                <Route
                                  path="/zahle-store/account/profile"
                                  element={<Profile />}
                                />

                                <Route
                                  path="/zahle-store/account/notifications"
                                  element={<Notifications />}
                                />

                                <Route
                                  path="/zahle-store/account/orders"
                                  element={<Orders />}
                                />

                                <Route
                                  path="/zahle-store/account/orders-test"
                                  element={<OrdersT />}
                                />

                                <Route
                                  path="/zahle-store/posprint"
                                  element={<PosPrinttest />}
                                />

                                <Route
                                  path="/zahle-store/account/profile-test"
                                  element={<ProfileT />}
                                />

                                <Route
                                  path={
                                    "/zahle-store/account/address/:id/edit-test"
                                  }
                                  element={<AddAddressesT />}
                                />

                                <Route
                                  path={"/zahle-store/account/address/add-test"}
                                  element={<AddAddressesT />}
                                />

                                <Route
                                  path="/zahle-store/account/wishlist/"
                                  element={<WishListT />}
                                />

                                <Route
                                  path="/zahle-store/account/order-details"
                                  element={<OrderDetails />}
                                />

                                <Route
                                  path="/zahle-store/account/recentlyViewed"
                                  element={<RecentlyViewed />}
                                />

                                <Route
                                  path="/zahle-store/account/order-reviews"
                                  element={<OrderReviews />}
                                />

                                <Route
                                  path="/zahle-store/failed"
                                  element={<Failed />}
                                />

                                <Route
                                  path="/zahle-store/success"
                                  element={<Success />}
                                />

                                <Route
                                  path="/zahle-store/PaymentSuccess"
                                  element={<PaymentSuccess />}
                                />

                                <Route
                                  path="/zahle-store/account/wishlist-test"
                                  element={<WishList />}
                                />

                                <Route
                                  path="/zahle-store/headertest/v2"
                                  element={<Testheader />}
                                />

                                <Route
                                  path="/zahle-store/checkout"
                                  element={<Checkout />}
                                />

                                <Route
                                  path="/zahle-store/PaySuccess"
                                  element={<MpgsSuccess />}
                                />

                                <Route
                                  path="/zahle-store/UbaPaySuccess"
                                  element={<UbaMpgsSuccess />}
                                />

                                <Route
                                  path={"/zahle-store/account/address/:id/edit"}
                                  element={<AddAddresses />}
                                />

                                <Route
                                  path={"/zahle-store/account/address/add"}
                                  element={<AddAddresses />}
                                />

                                <Route
                                  exact
                                  path={"/zahle-store/category/:id"}
                                  element={<Catalog />}
                                />

                                <Route
                                  exact
                                  path={"/zahle-storemanufacturer/:id"}
                                  element={<Catalog />}
                                />

                                <Route
                                  exact
                                  path={"/zahle-store/seller/:id"}
                                  element={<Catalog />}
                                />

                                <Route
                                  exact
                                  path="/zahle-store/:name/c=:id"
                                  element={<Anythingv2 />}
                                />

                                <Route
                                  exact
                                  path="/zahle-store/:name/c=:id"
                                  element={<Anythingv2 />}
                                />

                                <Route
                                  exact
                                  path="/zahle-store/:name/c=:id"
                                  element={<Anythingv2 />}
                                />

                                <Route
                                  path="/zahle-store/product/:id"
                                  element={<Product />}
                                />

                                {/* <Route path="/zahle-store/testouss/:id">
                                  <Suspense fallback={<div></div>}>
                                    <TestOuss />
                                  </Suspense>
                           
                                </Route> */}

                                <Route
                                  path="/zahle-store/:name/p/:id"
                                  exact
                                  element={<Productv2 />}
                                />

                                {/* <Route path="/zahle-store/test123" component={Test123} /> */}
                                <Route
                                  path="/zahle-store/productpreview/:id"
                                  element={<ProductPreview />}
                                />

                                <Route
                                  path="/zahle-store/widgetspreview"
                                  element={<WidgetsHomePreview />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/home"
                                  exact
                                  element={<DashboardSeller />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/products"
                                  exact
                                  element={<ProductsSeller />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/orders"
                                  exact
                                  element={<OrdersSeller />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/printOrderInvoice"
                                  exact
                                  element={<PrintOrderInvoice />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/ReturnOrders"
                                  exact
                                  element={<ReturnOrdersSeller />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/ReturnOrderDetails/:id"
                                  exact
                                  element={<ReturnOrderDetails />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/OrderDetails/:id"
                                  element={<OrderSellerDetails />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/accounting"
                                  exact
                                  element={<AccountingSeller />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/reports"
                                  exact
                                  element={<ReportsSeller />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/edit"
                                  exact
                                  element={<SellerEdit />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/EditProduct/:id"
                                  exact
                                  element={<ProductEdit />}
                                />

                                <Route
                                  path="/zahle-store/orders/"
                                  element={<PosOrders />}
                                />

                                <Route
                                  path="/zahle-store/seller_report/SoldProductDetails/:id"
                                  element={<SoldProductDetails />}
                                />

                                <Route
                                  path="/zahle-store/information/:id"
                                  element={<Information />}
                                />

                                <Route
                                  path="/zahle-store/information/contact"
                                  element={<Contact />}
                                />

                                <Route
                                  path="/zahle-store/contact"
                                  element={<Contact />}
                                />

                                <Route
                                  path="/zahle-store/:name"
                                  exact
                                  element={<Anything />}
                                />

                                <Route
                                  path="/zahle-store"
                                  exact
                                  element={<NotFound />}
                                />
                              </Routes>
                            </div>

                            {!isUserSeller && <Footer />}
                            {!isUserSeller && <Version />}
                          </ExportProductProvider>
                        </ProductProvider>
                      </AccountProvider>
                    </CurrencyProvider>
                  </WishlistProvider>
                </CartProvider>
              </FiltersProvider>
            </MessageProvider>
          )
        ) : (
          <>
            {window.location.href.split("/")[3] === "posprint" ||
            window.location.href.split("/")[3] === "ordersprint" ||
            window.location.href.split("/")[3] === "posprinthold" ? (
              <>
                <Routes>
                  <Route path="/posprint/:id">
                    <PosPrinttest />
                  </Route>
                  <Route path="/posprinthold/:id">
                    <PosPrintHold />
                  </Route>
                  <Route path="/ordersprint/">
                    <PosOrdersPrint />
                  </Route>
                </Routes>
              </>
            ) : (
              <ReviewCenterProvider>
                <MessageProvider>
                  <FiltersProvider>
                    <CartProvider>
                      <WishlistProvider>
                        <CurrencyProvider>
                          <AccountProvider>
                            <ProductProvider>
                              <ExportProductProvider>
                              <MarketingProvider>
                              <GoogleOAuthProvider className="my-auto" clientId="912888796514-5ctiope0r15nvno9iik1vf8ko90qtva0.apps.googleusercontent.com">

                                {!isUserSeller ? <TopHeader /> : <AsideMenu />}
                                <BottomBar />
                                <div
                                  className="text-dblack min-h-screen"
                                  // style={{ backgroundColor: "#f7f7fa" }}
                                >
                                  <Routes>
                                    <Route path="/" element={<Home />} />

                                    <Route
                                      path="/upload/upload"
                                      element={<ImageUpload />}
                                    />
                                  <Route
                                      path="/allcategories"
                                      element={<CategoryList />}
                                    />
                                    <Route
                                      path="/print/print"
                                      element={<Upc />}
                                    />

                                    <Route
                                      path="/print/details"
                                      element={<UpcDetails />}
                                    />

                                    <Route
                                      path="/careers/all"
                                      element={<Careers />}
                                    />

                                    <Route
                                      path="/flo-upc"
                                      element={<UpcMobile />}
                                    />

                                    <Route path="/pos" element={<Pos />} />

                                    <Route
                                      path="/fastbuy-admin-test"
                                      exact
                                      element={<QuickBuyAdmintest />}
                                    />

                                    <Route
                                      path="/test-payment/v3"
                                      exact
                                      element={<CheckoutV3 />}
                                    />

                                    <Route
                                      path="/career/:id"
                                      element={<Career />}
                                    />

                                    <Route
                                      path="/search"
                                      exact
                                      element={<Search />}
                                    />

                                    <Route
                                      path="/latest"
                                      loading
                                      element={<Latest />}
                                    />

                                    <Route path="/cart" element={<Cart />} />

                                    <Route
                                      path="/account/addresses"
                                      element={<Addresses />}
                                    />

                                    <Route
                                      path="/account/buyagain"
                                      element={<BuyAgain />}
                                    />

                                    <Route
                                      path="/account/profile"
                                      element={<Profile />}
                                    />

                                    <Route
                                      path="/account/wallet"
                                      element={<Wallet />}
                                    />

                                    <Route
                                      path="/account/follow"
                                      element={<Follow />}
                                    />

                                    <Route
                                      path="/account/reviewcenter"
                                      element={<ReviewCenter />}
                                    />

                                    <Route
                                      path="/account/feedback"
                                      element={<Feedback />}
                                    />

                                    <Route
                                      path="/account/notifications"
                                      element={<Notifications />}
                                    />

                                    <Route
                                      path="/account/orders"
                                      element={<Orders />}
                                    />

                                    <Route
                                      path="/account/orders-test"
                                      element={<OrdersT />}
                                    />

                                    <Route
                                      path="/posprint"
                                      element={<PosPrinttest />}
                                    />

                                    <Route
                                      path="/account/profile-test"
                                      element={<ProfileT />}
                                    />

                                    <Route
                                      path={"/account/address/:id/edit-test"}
                                      element={<AddAddressesT />}
                                    />

                                    <Route
                                      path={"/account/address/add-test"}
                                      element={<AddAddressesT />}
                                    />

                                    <Route
                                      path="/account/wishlist/"
                                      element={<WishListT />}
                                    />

                                    <Route
                                      path="/account/wishlistSimilar/"
                                      element={<WishlistSimilar />}
                                    />
                                    <Route
                                    path="/hc"
                                    element={<Hc />}
                                    />
                                    <Route
                                    path="/hc/:id"
                                    element={<HcItem />}
                                    />

                                    <Route
                                      path="/account/coupons/"
                                      element={<Coupons />}
                                    />
                                    <Route
                                      path="/account/checkin/"
                                      element={<CheckIn />}
                                    />
                                    <Route
                                      path="/account/memberShip/"
                                      element={<MenmberShip />}
                                    />
                                    <Route
                                      path="/account/memberShipDetails/"
                                      element={<MenmberShipDetails />}
                                    />

                                    <Route
                                      path="/shared_cart/"
                                      element={<CartShared />}
                                    />

                                    <Route
                                      path="/account/order-details"
                                      element={<OrderDetails />}
                                    />

                                    <Route
                                      path="/account/recentlyViewed"
                                      element={<RecentlyViewed />}
                                    />

                                    <Route
                                      path="/account/order-reviews"
                                      element={<OrderReviews />}
                                    />

                                    <Route
                                      path="/failed"
                                      element={<Failed />}
                                    />

                                    <Route
                                      path="/success"
                                      element={<Success />}
                                    />

                                    <Route
                                      path="/PaymentSuccess"
                                      element={<PaymentSuccess />}
                                    />

                                    <Route
                                      path="/account/wishlist-test"
                                      element={<WishList />}
                                    />

                                    <Route
                                      path="/headertest/v2"
                                      element={<Testheader />}
                                    />

                                    <Route
                                      path="/checkout"
                                      element={<Checkout />}
                                    />

                                    <Route
                                      path="/PaySuccess"
                                      element={<MpgsSuccess />}
                                    />

                                    <Route
                                      path="/UbaPaySuccess"
                                      element={<UbaMpgsSuccess />}
                                    />

                                    <Route
                                      path={"/account/address/:id/edit"}
                                      element={<AddAddresses />}
                                    />

                                    <Route
                                      path={"/account/address/add"}
                                      element={<AddAddresses />}
                                    />

                                    <Route
                                      exact
                                      path={"/category/:id"}
                                      element={<Catalog />}
                                    />

                                    <Route
                                      exact
                                      path={"/manufacturer/:id"}
                                      element={<Catalog />}
                                    />

                                    <Route
                                      exact
                                      path={"/seller/:id"}
                                      element={<Catalog />}
                                    />

                                    <Route
                                      path="/product/:id"
                                      element={<Product />}
                                    />

                                    {/* <Route path="/testouss/:id">
                                  <Suspense fallback={<div></div>}>
                                    <TestOuss />
                                  </Suspense>
                           
                                </Route> */}

                                    <Route
                                      path="/:name/:id"
                                      exact
                                      element={<Slug />}
                                    />

                                    {/* <Route path="/:name/:id" exact element={ <Anythingv2 />}/> */}

                                    {/* <Route path="/test123" component={Test123} /> */}
                                    <Route
                                      path="/productpreview/:id"
                                      element={<ProductPreview />}
                                    />

                                    <Route
                                      path="/widgetspreview"
                                      element={<WidgetsHomePreview />}
                                    />

                                    <Route
                                      path="/seller_report/home"
                                      exact
                                      element={<DashboardSeller />}
                                    />

                                    <Route
                                      path="/seller_report/products"
                                      exact
                                      element={<ProductsSeller />}
                                    />

                                    <Route
                                      path="/seller_report/orders"
                                      exact
                                      element={<OrdersSeller />}
                                    />

                                    <Route
                                      path="/seller_report/printOrderInvoice"
                                      exact
                                      element={<PrintOrderInvoice />}
                                    />

                                    <Route
                                      path="/seller_report/ReturnOrders"
                                      exact
                                      element={<ReturnOrdersSeller />}
                                    />

                                    <Route
                                      path="/seller_report/ReturnOrderDetails/:id"
                                      exact
                                      element={<ReturnOrderDetails />}
                                    />

                                    <Route
                                      path="/seller_report/OrderDetails/:id"
                                      element={<OrderSellerDetails />}
                                    />

                                    <Route
                                      path="/seller_report/accounting"
                                      exact
                                      element={<AccountingSeller />}
                                    />

                                    <Route
                                      path="/seller_report/reports"
                                      exact
                                      element={<ReportsSeller />}
                                    />

                                    <Route
                                      path="/seller_report/edit"
                                      exact
                                      element={<SellerEdit />}
                                    />

                                    <Route
                                      path="/seller_report/EditProduct/:id"
                                      exact
                                      element={<ProductEdit />}
                                    />

                                    <Route
                                      path="/orders/"
                                      element={<PosOrders />}
                                    />

                                    <Route
                                      path="/seller_report/SoldProductDetails/:id"
                                      element={<SoldProductDetails />}
                                    />

                                    <Route
                                      path="/information/:id"
                                      element={<Information />}
                                    />

                                    <Route
                                      path="/information/contact"
                                      element={<Contact />}
                                    />

                                    <Route
                                      path="/contact"
                                      element={<Contact />}
                                    />
                                    <Route
                                      path="/account"
                                      element={<Account/>}
                                    />

                                    <Route
                                      path="/:name"
                                      exact
                                      element={<Anything />}
                                    />

                                    <Route
                                      path=""
                                      exact
                                      element={<NotFound />}
                                    />
                                  </Routes>
                                </div>

                                {!isUserSeller &&
                                  window.location.href.split("/")[3] !==
                                    "pos" && <Footer />}

                                {!isUserSeller && <Version />}
                                </GoogleOAuthProvider>

                                </MarketingProvider>
                              </ExportProductProvider>
                            </ProductProvider>
                          </AccountProvider>
                        </CurrencyProvider>
                      </WishlistProvider>
                    </CartProvider>
                  </FiltersProvider>
                </MessageProvider>
              </ReviewCenterProvider>
            )}
          </>
        )}
      </StrictMode>
    </div>
  );
}

export default App;
