import { Link } from "react-router-dom";
import { path } from "../urls";
import { BsFillCartCheckFill } from 'react-icons/bs';
import { MdAvTimer } from 'react-icons/md';
import { RiCoupon3Fill } from "react-icons/ri";
import { FaCheckCircle, FaTv } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import { FaWallet } from "react-icons/fa";

function Account(props) {
    return (
        <div className="flex flex-col p-4 md:hidden space-y-2">
            {/* Account Management Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b pb-2 border-b-dgreyQtyProduct">Account Management</h2>
                <div className="flex ">
                    <Link to={`${path}/account/profile`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <i className="icon icon-user-solid text-2xl text-black"></i>
                        </div>
                        <span className="font-semibold text-black text-sm">Profile</span>
                    </Link>
                    <Link to={`${path}/account/addresses`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <i className="icon icon-location text-2xl text-black"></i>
                        </div>
                        <span className="font-semibold text-black text-sm">Addresses</span>
                    </Link>
                </div>
            </div>

            {/* Orders Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b border-b-dgreyQtyProduct pb-2">Orders</h2>
                <div className="flex ">
                    <Link to={`${path}/account/orders`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <i className="icon icon-dollar text-2xl text-black"></i>
                        </div>
                        <span className="font-semibold text-black text-sm">Orders</span>
                    </Link>
                    <Link to={`${path}/account/coupons`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <RiCoupon3Fill className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Coupons</span>
                    </Link>
                    <Link to={`${path}/account/checkin`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <FaCheckCircle className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Checkin</span>
                    </Link>
                    <Link to={`${path}/account/buyagain`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <BsFillCartCheckFill className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Buy Again</span>
                    </Link>
                </div>
            </div>

            {/* Membership Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b border-b-dgreyQtyProduct pb-2">Membership</h2>
                <div className="flex ">
                    <Link to={`${path}/account/memberShip`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <FaTv className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Membership</span>
                    </Link>
                    <Link to={`${path}/account/wallet`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <FaWallet className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Wallet</span>
                    </Link>
                </div>
            </div>

            {/* Feedback Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b pb-2 border-b-dgreyQtyProduct">Feedback</h2>
                <div className="flex justify-start">
                    <Link to={`${path}/account/feedback`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <MdFeedback className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Feedback</span>
                    </Link>
                </div>
            </div>

            {/* Wishlist and Recently Viewed Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b pb-2 border-b-dgreyQtyProduct">Wishlist</h2>
                <div className="flex">
                    <Link to={`${path}/account/wishlist`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <i className="icon icon-heart text-2xl text-black"></i>
                        </div>
                        <span className="font-semibold text-black text-sm">Wishlist</span>
                    </Link>
                    <Link to={`${path}/account/recentlyViewed`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <MdAvTimer className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm whitespace-nowrap">Recently Viewed</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Account;
